import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./../styles/staff/Staff.css";
import "./../styles/general/Reset.css";
import Nav from "./../navigation/Nav";

function BargainingUnits() {
  const [bargainingUnitList, setBargainingUnitList] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "LOCAL", direction: "ascending" });
  const navigate = useNavigate();

  useEffect(() => {
    // Check for token
    const token = sessionStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    // Fetch bargaining units with token
    fetch("/api/units", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          sessionStorage.removeItem("token");
          navigate("/login");
          throw new Error("Unauthorized");
        }
        return response.json();
      })
      .then((data) => setBargainingUnitList(data))
      .catch((error) => console.error("Error fetching Bargaining Unit Data: ", error));
  }, [navigate]);

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    setBargainingUnitList((bargainingUnitList) =>
      [...bargainingUnitList].sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      })
    );
  };

  return (
    <div className="page-container">
      <Nav />
      <div className="content-wrapper">
        <header className="page-header">
          <h1 className="page-title">Bargaining Units</h1>
        </header>

        <main className="main-content">
          <div className="table-container">
            <table className="data-table">
              <thead>
                <tr>
                  {["LOCAL", "UNIT", "NAME", "TOWN", "REP ASSIGNED"].map((column) => (
                    <th
                      key={column}
                      onClick={() => sortData(column)}
                      className={`sortable-header ${sortConfig.key === column ? `sorted-${sortConfig.direction}` : ""}`}
                    >
                      {column}
                      {sortConfig.key === column && (
                        <span className="sort-indicator">
                          {sortConfig.direction === "ascending" ? " ▲" : " ▼"}
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {bargainingUnitList.map((unit) => (
                  <tr key={unit.id} className="data-row">
                    <td className="cell">{unit.LOCAL}</td>
                    <td className="cell">{unit.UNIT.toString().padStart(4, "0")}</td>
                    <td className="cell">
                      <Link to={`/unit-info/${unit.id}`} className="table-link">{unit.NAME}</Link>
                    </td>
                    <td className="cell">
                      <Link to={`/town/${unit.TOWN}`} className="table-link">{unit.TOWN}</Link>
                    </td>
                    <td className="cell">
                      <Link to={`/employee/${unit.STAFF_ASSIGNED_ID}`} className="table-link">{unit.STAFF_ASSIGNED}</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
  );
}

export default BargainingUnits;