import React, { useState, useEffect } from "react";
import Nav from "./../navigation/Nav";
import EditableTableRow from "./../general/EditableTableRow";
import { useParams } from "react-router-dom"; // For accessing URL parameters
import "./../styles/units/Unit.css";
import "./../styles/general/Grid.css";
import { useAuth } from "./../general/AuthContext";
import { useNavigate } from "react-router-dom";
import DraggableEditButton from "./DraggableEditButton";

const username = sessionStorage.getItem("username");  // Retrieve username from sessionStorage

const requestBody = {
  // Other data being sent in the request
  username,  // Include the username in the request body
  // other fields...
};

console.log('The user who will be logged is ' + requestBody.username)

function formatDate(dateString) {
  if (!dateString || isNaN(new Date(dateString).getTime())) {
    console.warn("Invalid date value passed to formatDate:", dateString);
    return null; // React-datepicker may need `null` for invalid dates
  }
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function BargainingUnitInfo() {
  const { id } = useParams();
  const navigate = useNavigate(); // Initialize navigate function
  const [employees, setEmployees] = useState([]);
  const [towns, setTowns] = useState([]);
  const [staffReps, setStaffReps] = useState([]);
  const [fieldReps, setFieldReps] = useState([]);
  const [organizers, setOrganizers] = useState([]);
  const [serviceFieldReps, setServiceFieldReps] = useState([]);
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState("");
  const { auth, setAuth } = useAuth();
  const [bargainingUnits, setBargainingUnits] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [unitsData, setUnitsData] = useState([]);
  
  useEffect(() => {
    const fetchAllUnitsData = async () => {
      try {
        const response = await fetch("/api/bargaining-units"); // Ensure this endpoint is implemented on the server
        const data = await response.json();
        setUnitsData(data); // Store all unit data in state
        if (data.length > 0) setCurrentIndex(0);
      } catch (error) {
        console.error("Failed to fetch unit info:", error);
      }
    };

    fetchAllUnitsData();
  }, []);

  useEffect(() => {
    // Fetch all units data here and initialize the current index based on the URL
    const fetchData = async () => {
      try {
        const response = await fetch("/api/bargaining-units");
        const data = await response.json();
        setUnitsData(data);
        const initialIndex = data.findIndex(
          (unit) => unit.id.toString() === id
        );
        setCurrentIndex(initialIndex >= 0 ? initialIndex : 0);
      } catch (error) {
        console.error("Failed to fetch unit info:", error);
      }
    };

    fetchData();
  }, [id]);

  const fetchUnitData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        console.error("No token found. Redirecting to login.");
        return navigate("/login");
      }
  
      const response = await fetch(`/api/unit-info/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        if (response.status === 401) {
          console.error("Unauthorized access. Redirecting to login.");
          sessionStorage.removeItem("token");
          return navigate("/login");
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      BargainingUnitInfoSetData(data);
      setEditData(data);
      console.log("Data fetched successfully after save:", data);
    } catch (error) {
      console.error("Error fetching updated data:", error);
    }
  };


  const goToNextUnit = () => {
    const nextIndex =
      currentIndex === unitsData.length - 1 ? 0 : currentIndex + 1;
    navigate(`/units/${unitsData[nextIndex].id}`);
  };

  const goToPreviousUnit = () => {
    const prevIndex =
      currentIndex === 0 ? unitsData.length - 1 : currentIndex - 1;
    navigate(`/units/${unitsData[prevIndex].id}`);
  };

  useEffect(() => {
    if (!auth.isAuthenticated) {
      navigate("/login");
    }
  }, [auth, navigate]);

  useEffect(() => {
    // Fetch notes from the API
    fetch(`/api/notes/${id}`)
      .then((response) => response.json())
      .then((data) => setNotes(data))
      .catch((error) => console.error("Failed to fetch notes:", error));
  }, [id]);

  const handleNoteChange = (index, value) => {
    const updatedNotes = notes.map((note, i) => {
      if (i === index) {
        return { ...note, note: value };
      }
      return note;
    });
    setNotes(updatedNotes);
  };

  const addNote = () => {
    const newNoteObject = {
      bargainingUnitId: id,
      note: newNote,
      datetime: new Date().toISOString(), // Adjust based on your date handling
    };
    fetch("/api/notes", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newNoteObject),
    })
      .then((response) => response.json())
      .then((data) => {
        setNotes([...notes, data]);
        setNewNote(""); // Reset the input after adding
      })
      .catch((error) => console.error("Failed to add note:", error));
  };

  const deleteNote = (noteId, index) => {
    fetch(`/api/notes/${noteId}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          setNotes(notes.filter((_, i) => i !== index));
        } else {
          throw new Error("Failed to delete the note.");
        }
      })
      .catch((error) => console.error("Failed to delete note:", error));
  };
  useEffect(() => {
    const fetchStaffReps = async () => {
      try {
        // Get the authentication token from auth context or sessionStorage
        const token = auth?.token || sessionStorage.getItem("token");
        if (!token) {
          console.error("No token found. Redirecting to login.");
          navigate("/login"); // Redirect to login if no token is found
          return;
        }
  
        // Fetch staff reps data with token in headers
        const response = await fetch('/api/staff-reps', {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the header
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        setStaffReps(Array.isArray(data) ? data : []); // Ensure data is an array
      } catch (error) {
        console.error("Error fetching staff reps:", error);
        setStaffReps([]); // Set an empty array on error
      }
    };
  
    fetchStaffReps();
  }, [auth.token, navigate]); // Add dependencies to ensure proper token use

  useEffect(() => {
    fetch('/api/field-reps', {
        headers: {
            Authorization: `Bearer ${auth.token}`,
        },
    })
        .then(response => response.json())
        .then(data => {
            console.log('Field Reps API Response:', data);
            setFieldReps(Array.isArray(data) ? data : []);
        })
        .catch(error => {
            console.error('Error fetching field reps:', error);
            setFieldReps([]);
        });
}, [auth.token]);

useEffect(() => {
  fetch("/api/organizers", {
      headers: {
          Authorization: `Bearer ${auth.token}`, // Include token if required
      },
  })
      .then((response) => response.json())
      .then((data) => {
          if (Array.isArray(data)) {
              setOrganizers(data);
          } else {
              console.error("Unexpected data format for organizers:", data);
              setOrganizers([]);
          }
      })
      .catch((error) => {
          console.error("Error fetching organizers:", error);
          setOrganizers([]);
      });
}, [auth.token]);

useEffect(() => {
  fetch("/api/service-reps", {
      headers: {
          Authorization: `Bearer ${auth.token}`,
      },
  })
      .then((response) => response.json())
      .then((data) => {
          if (Array.isArray(data)) {
              setServiceFieldReps(data);
          } else {
              console.error("Unexpected data format for service reps:", data);
              setServiceFieldReps([]);
          }
      })
      .catch((error) => {
          console.error("Error fetching service reps:", error);
          setServiceFieldReps([]);
      });
}, [auth.token]);

useEffect(() => {
  fetch("/api/allemployees", {
      headers: {
          Authorization: `Bearer ${auth.token}`,
      },
  })
      .then((response) => response.json())
      .then((data) => {
          if (Array.isArray(data)) {
              setEmployees(data);
          } else {
              console.error("Unexpected data format for employees:", data);
              setEmployees([]);
          }
      })
      .catch((error) => {
          console.error("Error fetching employees:", error);
          setEmployees([]);
      });
}, [auth.token]);

useEffect(() => {
  fetch("/api/towns", {
      headers: {
          Authorization: `Bearer ${auth.token}`,
      },
  })
      .then((response) => response.json())
      .then((data) => {
          if (Array.isArray(data)) {
              setTowns(data);
          } else {
              console.error("Unexpected data format for towns:", data);
              setTowns([]);
          }
      })
      .catch((error) => {
          console.error("Error fetching towns:", error);
          setTowns([]);
      });
}, [auth.token]);

  const [isEditMode, setIsEditMode] = useState(false);
  const [editData, setEditData] = useState({
    RepAssigned1: "",
    RepAssigned2: "",
    RepAssigned1_Id: "",
    RepAssigned2_Id: "",
    ServiceFieldRep1: "",
    ServiceFieldRep2: "",
    ServiceFieldRep1_Id: "",
    ServiceFieldRep2_Id: "",
    FieldRep1: "",
    FieldRep1_Id: "",
    Organizer: "",
   Organizer_Id: "",
    LOCAL: "",
    UNIT: "",
    BARGAINING_UNIT: "",
    TOWN: "",
    CBA_START: "",
    CBA_EXP: "",
    PresidentFirstName: "",
    PresidentLastName: "",
    PresidentAddress:"",
	PresidentCity: "",
	PresidentState: "",
	PresidentZipcode: "",
	PresidentHomePhone: "",
	PresidentWorkPhone: "",
	PresidentCellPhone: "",
	PresidentOtherPhone: "",
	PresidentLocalAddress: "",
    VicePresidentFirstName: "",
    VicePresidentLastName: "",
    VicePresidentAddress: "",
	VicePresidentCity: "",
	VicePresidentState: "",
	VicePresidentZipcode: "",
	VicePresidentHomePhone: "",
	VicePresidentWorkPhone: "",
	VicePresidentCellPhone: "",
	VicePresidentOtherPhone: "",
	VicePresidentLocalAddress: "",
    TreasurerFirstName: "",
    TreasurerLastName: "",
	TreasurerCity: "",
	TreasurerState: "",
  TreasurerAddress: "",
	TreasurerZipcode: "",
	TreasurerHomePhone: "",
	TreasurerCellPhone: "",
	TreasurerWorkPhone: "",
	TreasurerOtherPhone: "",
	TreasurerLocalAddress: "",
    RecordingSecretaryFirstName: "",
    RecordingSecretaryLastName: "",
    RecordingSecretaryAddress: "",
	RecordingSecretaryCity: "",
	RecordingSecretaryState: "",
	RecordingSecretaryZipcode: "",
	RecordingSecretaryHomePhone: "",
	RecordingSecretaryWorkPhone: "",
	RecordingSecretaryCellPhone: "",
	RecordingSecretaryOtherPhone: "",
	RecordingSecretaryLocalAddress: "",
    PayrollHRContactName: "",
    PayrollHRContactEmail: "",
    PayrollHRContactNumber: "",
    NewRecommendedContractLanguage: "",
    AreOrientationsHappening: "",
    WhoDoesOrientation: "",
    ActiveJobCount: "",
    TotalDuesPayers: "",
    OnCentralizedDues: "",
    DuesMonthApplied: "",
    DuesFrequency: "",
    NumberOfHomeEmails: "",
    NumberOfPhoneNumbers: "",
    DateLastBargUnitListReceived: "",
    Refusals: "",
    AuditCompleteOverview: "",
    DateAudited: "",
    PEOPLEContributors: "",
    TotalOPTOUTS: "",
    RecommitsSaves: "",
    DateLastOfficerUpdate: "",
    LastElection: "",
    IUMonthYearNextElection: "",
    LengthOfTerm: "",
    PresidentFIRST: "",
    PresidentLast: "",
    PresidentContactNumber: "",
    PresidentEmail: "",
    Notes: "",
    // Add other fields as necessary
  });

  const [BargainingUnitInfoData, BargainingUnitInfoSetData] = useState({
    membershipInformation: {},
    payrollInformation: {},
    contractsAndNegotiations: {},
    membershipMetrics: {},
    auditsAndCompliance: {},
    financialMetrics: {},
    electionAndOfficerInformation: {},
    additionalNotes: {},
    localPresident: {},
    localVicePresident: {},
    localTreasurer: {},
    localSecretary: {}

  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem("token");
        if (!token) {
          console.error("No token found. Redirecting to login.");
          return navigate("/login");
        }
  
        const response = await fetch(`/api/unit-info/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
  
        if (!response.ok) {
          if (response.status === 401) {
            console.error("Unauthorized access. Redirecting to login.");
            sessionStorage.removeItem("token");
            return navigate("/login");
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
  
        // Validate dates before setting data
        const validatedData = {
          ...data,
          CBA_START: data.CBA_START || null,
          CBA_EXP: data.CBA_EXP || null,
        };
  
        BargainingUnitInfoSetData(validatedData);
        setEditData(validatedData);
        console.log("Data fetched successfully:", validatedData);
      } catch (error) {
        console.error("Error fetching unit info:", error);
      }
    };
  
    fetchData();
  }, [id]);

  const handleEditToggle = () => {
    setIsEditMode(!isEditMode);
  };

  const handleInputChange = (e) => {
    const { name, value, idKey, idValue } = e.target;
    console.log(
      `Handling change for ${name}, ID Key: ${idKey}, ID Value: ${idValue}`
    );
    setEditData((prevData) => {
      const newData = {
        ...prevData,
        [name]: value,
        ...(idKey && { [idKey]: idValue }),
      };
      console.log("New editData:", newData);
      return newData;
    });
  };
  const handleSave = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const username = sessionStorage.getItem("username");
  
      if (!token) {
        console.error("No token found. Redirecting to login.");
        return navigate("/login");
      }
  
      const validData = { ...editData, updatedBy: username };
      if (!validData.id) delete validData.id;
      if (!validData.bargainingUnitId) delete validData.bargainingUnitId;
      if (!validData.UserId) delete validData.UserId;
  
      validData.CBA_START = validData.CBA_START || null;
      validData.CBA_EXP = validData.CBA_EXP || null;
  
      const response = await fetch(`/api/unit-info/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(validData),
      });
  
      if (!response.ok) throw new Error("Failed to save data");
  
      console.log("Data saved successfully");
      
      // Update the UI state immediately
      BargainingUnitInfoSetData(validData);
      
      // This is the critical line that switches from save back to edit
      setIsEditMode(false);
      
      // Optional: show a success message
      
      // Fetch the latest data from server (optional)
      await fetchUnitData();
      
    } catch (error) {
      console.error("Error saving unit info:", error);
      alert("Failed to save changes. Please try again.");
      // Don't change edit mode on error
    }
  };
  

  return (
    <>
      <Nav />
      <div className="infoContainer">
        <div className="navigationContainer">
          <div className="prevButtonContainer">
            <button onClick={goToPreviousUnit}>&lt; Previous</button>
            {unitsData.length > 0 && currentIndex > 0 && (
              <div className="unitName">{unitsData[currentIndex - 1].NAME}</div>
            )}
          </div>

          <div className="nextButtonContainer">
            <button onClick={goToNextUnit}>Next &gt;</button>
            {unitsData.length > 0 && currentIndex < unitsData.length - 1 && (
              <div className="unitName">{unitsData[currentIndex + 1].NAME}</div>
            )}
          </div>
        </div>

        <DraggableEditButton 
          isEditMode={isEditMode}
          handleEditToggle={handleEditToggle}
          handleSave={handleSave}
          onClick={handleEditToggle}
        />

        <div className="action-buttons">
          <span
            style={{
              fontSize: "20px",
              cursor: "pointer",
              padding: "11px",
              textAlign: "left",
              fontWeight: "bold",
              color: "#fff",
              backgroundColor: "red",
              borderRadius: "8px",
            }}
            onClick={handleEditToggle}
          >
            {isEditMode ? "Cancel" : "Edit Unit Information"}
          </span>

          {isEditMode && (
            <span
              onClick={handleSave}
              style={{
                fontSize: "20px",
                cursor: "pointer",
                padding: "10px",
                textAlign: "center",
                backgroundColor: "lightgreen",
                width: "100px",

                borderRadius: "8px",
                marginLeft: "10px",
                fontWeight: 700,
                color: "#000",
              }}
            >
              Save
            </span>
          )}
        </div>
        <div className="employee_info">
          <h1>{editData.RepAssigned1}</h1>
          <h1>{editData.RepAssigned2}</h1>
          <h1>
  {`${editData.LOCAL || "N/A"} - ${
    editData.UNIT ? editData.UNIT.toString().padStart(4, "0") : "0000"
  } - ${editData.BARGAINING_UNIT || "N/A"}`}
</h1>
        </div>
      </div>

      <div className="container">
        <div
          className="grid-container"
          style={
            isEditMode
              ? {
                  backgroundColor: "#f6ffae",
                  border: "2px solid #eee",
                  padding: "5px",
                  borderRadius: "8px",
                }
              : { backgroundColor: "#fff", padding: "5px" }
          }
        >
          <div className="grid-item">
            <h4>Membership Information </h4>
            <table>
              <tbody>
              <EditableTableRow
                  label="Staff Rep"
                  value={editData.RepAssigned1 || ""}
                  name="RepAssigned1"
                  onChange={handleInputChange}
                  isEditMode={isEditMode}
                  isDropdown={true}
                  options={Array.isArray(staffReps) ? staffReps.map(rep => ({
                      id: rep.EmployeeId,
                      employee_full_name: rep.full_name,
                  })) : []}
                  hiddenData={{ hiddenId1: editData.RepAssigned1_Id }}
                />
                <EditableTableRow
                  label="Service Rep"
                  value={editData.ServiceFieldRep1 || ""}
                  name="ServiceFieldRep1"
                  onChange={handleInputChange}
                  isEditMode={isEditMode}
                  isDropdown={true}
                  options={serviceFieldReps.map((rep) => ({
                    id: rep.EmployeeId,
                    employee_full_name: rep.full_name,
                  }))}
                  hiddenData={{ hiddenId2: editData.ServiceFieldRep1_Id }}
                />

                <EditableTableRow
                  label="Field Rep"
                  value={editData.FieldRep1 || ""}
                  name="FieldRep1"
                  onChange={handleInputChange}
                  isEditMode={isEditMode}
                  isDropdown={true}
                  options={fieldReps.map((rep) => ({
                    id: rep.EmployeeId,
                    employee_full_name: rep.full_name,
                  }))}
                  hiddenData={{ hiddenId3: editData.FieldRep1_Id }}
                />

                <EditableTableRow
                  label="Organizer"
                  value={editData.Organizer || ""}
                  name="Organizer"
                  onChange={handleInputChange}
                  isEditMode={isEditMode}
                  isDropdown={true}
                  options={organizers.map((rep) => ({
                    id: rep.EmployeeId,
                    employee_full_name: rep.full_name,
                  }))}
                  hiddenData={{ hiddenId4: editData.Organizer_Id }}
                />

                <EditableTableRow
                  label="Staff Rep 2"
                  value={editData.RepAssigned2 || ""}
                  name="RepAssigned2"
                  onChange={handleInputChange}
                  isEditMode={isEditMode}
                  isDropdown={true}
                  options={staffReps.map((rep) => ({
                    id: rep.EmployeeId,
                    employee_full_name: rep.full_name,
                  }))}
                  hiddenData={{ hiddenId5: editData.RepAssigned2_Id }}
                />

                {/* <EditableTableRow label="Local" value={editData.LOCAL || ''} name="LOCAL" onChange={handleInputChange} isEditMode={isEditMode} />

								<EditableTableRow label="Chapter" value={editData.UNIT || ''} name="UNIT" onChange={handleInputChange} isEditMode={isEditMode} />

								<EditableTableRow label="Bargaining Unit" value={editData.BARGAINING_UNIT || ''} name="BARGAINING_UNIT" onChange={handleInputChange} isEditMode={isEditMode} />
                <EditableTableRow
                  label="Town"
                  value={editData.TOWN || ""}
                  name="TOWN"
                  onChange={handleInputChange}
                  isEditMode={isEditMode}
                  isDropdown={true}
                  options={towns.map((town) => ({
                    id: town.Name,
                    employee_full_name: town.Name,
                  }))}
                />

                  */}
 <EditableTableRow
  label="CBA Start"
  value={editData.CBA_START ? new Date(editData.CBA_START) : null}
  name="CBA_START"
  onChange={(date) =>
    setEditData((prevData) => ({
      ...prevData,
      CBA_START: date ? date.toISOString() : null,
    }))
  }
  isEditMode={isEditMode}
  isDate={true}
/>

                <EditableTableRow
                  label="CBA_EXP"
                  value={formatDate(editData.CBA_EXP) || null}
                  name="CBA_EXP"
                  onChange={handleInputChange}
                  isEditMode={isEditMode}
                  isDate={true}
                />
              </tbody>
            </table>
          </div>

          <div className="grid-item">
            <h4>Payroll and HR Contacts</h4>

            <table>
              <EditableTableRow
                label="Payroll Contact Name"
                value={editData.PayrollHRContactName || ""}
                name="PayrollHRContactName"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Payroll Contact Email"
                value={editData.PayrollHRContactEmail || ""}
                name="PayrollHRContactEmail"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Payroll Contact Number"
                value={editData.PayrollHRContactNumber || ""}
                name="PayrollHRContactNumber"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
            </table>
          </div>

          {/* Contract and Negotiations */}
          <div className="grid-item">
            <h4>Contract and Negotiations </h4>
            <table>
              <EditableTableRow
                label="New Recommended Contract Language"
                value={editData.AreOrientationsHappening || ""}
                name="AreOrientationsHappening"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Are Orientations Happening"
                value={editData.NewRecommendedContractLanguage || ""}
                name="NewRecommendedContractLanguage"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Who Does Orientation"
                value={editData.WhoDoesOrientation || ""}
                name="WhoDoesOrientation"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
            </table>
          </div>

          {/* Membership Metrics */}
          <div className="grid-item">
            <h4>Membership and Metrics </h4>
            <table>
              <EditableTableRow
                label="Active Job Count"
                value={editData.ActiveJobCount || ""}
                name="ActiveJobCount"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              {/* Add other fields as necessary 
              <EditableTableRow
                label="Who does Orientations"
                value={editData.WhoDoesOrientation || ""}
                name="WhoDoesOrientation"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              */}
              <EditableTableRow
                label="Total Dues Payers"
                value={editData.TotalDuesPayers || ""}
                name="TotalDuesPayers"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="On Centralized Dues?"
                value={editData.OnCentralizedDues || ""}
                name="OnCentralizedDues"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Dues Month Applied"
                value={editData.DuesMonthApplied || ""}
                name="DuesMonthApplied"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Dues Frequency"
                value={editData.DuesFrequency || ""}
                name="DuesFrequency"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Number of Home Emails"
                value={editData.NumberOfHomeEmails || ""}
                name="NumberOfHomeEmails"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Number of Phone Numbers"
                value={editData.NumberOfPhoneNumbers || ""}
                name="NumberOfPhoneNumbers"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
            </table>
          </div>

          {/* Audits and Compliance */}
          <div className="grid-item">
            <h4>Audits and Compliance </h4>
            <table>
              <EditableTableRow
                label="Date Last Barg Unit List Received"
                value={formatDate(editData.DateLastBargUnitListReceived) || ""}
                name="DateLastBargUnitListReceived"
                onChange={handleInputChange}
                isEditMode={isEditMode}
                isDate={true}
              />
              <EditableTableRow
                label="Refusals"
                value={editData.Refusals || ""}
                name="Refusals"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Audit Complete Overview"
                value={formatDate(editData.AuditCompleteOverview) || ""}
                name="AuditCompleteOverview"
                onChange={handleInputChange}
                isEditMode={isEditMode}
                isDate={true}
              />
            </table>
          </div>

          {/* Financial Metrics */}
          <div className="grid-item">
            <h4>Financial Metrics </h4>

            <table>
              <EditableTableRow
                label="PEOPLE Contributors"
                value={editData.PEOPLEContributors || ""}
                name="PEOPLEContributors"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Recomits/Saves"
                value={editData.RecommitsSaves || ""}
                name="RecommitsSaves"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Total Opt Outs"
                value={editData.TotalOPTOUTS || ""}
                name="TotalOPTOUTS"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
            </table>
          </div>

          <div className="grid-item">
            <h4>Election and Officer Information </h4>
            <table>
              <EditableTableRow
                label="Date of Last Officer Update"
                value={formatDate(editData.DateLastOfficerUpdate) || ""}
                name="DateLastOfficerUpdate"
                onChange={handleInputChange}
                isEditMode={isEditMode}
                isDate={true}
              />
              <EditableTableRow
                label="Date of Last Election"
                value={formatDate(editData.LastElection) || ""}
                name="LastElection"
                onChange={handleInputChange}
                isEditMode={isEditMode}
                isDate={true}
              />
              <EditableTableRow
                label="IU Year of Next Election"
                value={formatDate(editData.IUMonthYearNextElection) || ""}
                name="IUMonthYearNextElection"
                onChange={handleInputChange}
                isEditMode={isEditMode}
                isDate={true}
              />

              <EditableTableRow
                label="Lenghth of Term"
                value={editData.LengthOfTerm || ""}
                name="LengthOfTerm"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              {/* President Information 
              <EditableTableRow
                label="President First Name"
                value={editData.PresidentFirst || ""}
                name="PresidentFirst"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="President Last Name"
                value={editData.PresidentLast || ""}
                name="PresidentLast"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

              <EditableTableRow
                label="President Contact Number"
                value={editData.PresidentContactNumber || ""}
                name="PresidentContactNumber"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="President Email"
                value={editData.PresidentEmail || ""}
                name="PresidentEmail"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
            */}
            </table>
          </div>

          <div className="grid-item">
            <h4>Local President</h4>
            <table>
              <EditableTableRow
                label="First Name"
                value={editData.PresidentFirstName || ""}
                name="PresidentFirstName"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

              <EditableTableRow
                label="Last Name"
                value={editData.PresidentLastName || ""}
                name="PresidentLastName"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Street"
                value={editData.PresidentAddress || ""}
                name="PresidentAddress"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

			<EditableTableRow
                label="City"
                value={editData.PresidentCity || ""}
                name="PresidentCity"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="State"
                value={editData.PresidentState || ""}
                name="PresidentState"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="Zip Code"
                value={editData.PresidentZipcode || ""}
                name="PresidentZipcode"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
				<EditableTableRow
					label="Cell Phone"
					value={editData.PresidentCellPhone || ""}
					name="PresidentCellPhone"
					onChange={handleInputChange}
					isEditMode={isEditMode}
				  />
			<EditableTableRow
                label="Home Phone"
                value={editData.PresidentHomePhone || ""}
                name="PresidentHomePhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  <EditableTableRow
                label="Work Phone"
                value={editData.PresidentWorkPhone || ""}
                name="PresidentWorkPhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

			  <EditableTableRow
                label="Other Phone"
                value={editData.PresidentOtherPhone || ""}
                name="PresidentOtherPhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="Local Address"
                value={editData.PresidentLocalAddress || ""}
                name="PresidentLocalAddress"
                onChange={handleInputChange}
                isEditMode={isEditMode}
				/>
				</table>
</div>
				<div className="grid-item">
            <h4>Local Vice President</h4>
            <table>
              <EditableTableRow
                label="First Name"
                value={editData.VicePresidentFirstName || ""}
                name="VicePresidentFirstName"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

              <EditableTableRow
                label="Last Name"
                value={editData.VicePresidentLastName || ""}
                name="VicePresidentLastName"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
  <EditableTableRow
                label="Street"
                value={editData.VicePresidentAddress || ""}
                name="VicePresidentAddress"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

			<EditableTableRow
                label="City"
                value={editData.VicePresidentCity || ""}
                name="VicePresidentCity"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="State"
                value={editData.VicePresidentState || ""}
                name="VicePresidentState"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="Zip Code"
                value={editData.VicePresidentZipcode || ""}
                name="VicePresidentZipcode"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
				<EditableTableRow
					label="Cell Phone"
					value={editData.VicePresidentCellPhone || ""}
					name="VicePresidentCellPhone"
					onChange={handleInputChange}
					isEditMode={isEditMode}
				  />
			<EditableTableRow
                label="Home Phone"
                value={editData.VicePresidentHomePhone || ""}
                name="VicePresidentHomePhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  <EditableTableRow
                label="Work Phone"
                value={editData.VicePresidentWorkPhone || ""}
                name="VicePresidentWorkPhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

			  <EditableTableRow
                label="Other Phone"
                value={editData.VicePresidentOtherPhone || ""}
                name="VicePresidentOtherPhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="Local Address"
                value={editData.VicePresidentLocalAddress || ""}
                name="VicePresidentLocalAddress"
                onChange={handleInputChange}
                isEditMode={isEditMode}
				/>
				</table>
</div>
<div className="grid-item">
            <h4>Local Treasurer</h4>
            <table>
              <EditableTableRow
                label="First Name"
                value={editData.TreasurerFirstName || ""}
                name="TreasurerFirstName"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

              <EditableTableRow
                label="Last Name"
                value={editData.TreasurerLastName || ""}
                name="TreasurerLastName"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Street"
                value={editData.TreasurerAddress || ""}
                name="TreasurerAddress"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />


			<EditableTableRow
                label="City"
                value={editData.TreasurerCity || ""}
                name="TreasurerCity"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="State"
                value={editData.TreasurerState || ""}
                name="TreasurerState"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="Zip Code"
                value={editData.TreasurerZipcode || ""}
                name="TreasurerZipcode"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
				<EditableTableRow
					label="Cell Phone"
					value={editData.TreasurerCellPhone || ""}
					name="TreasurerCellPhone"
					onChange={handleInputChange}
					isEditMode={isEditMode}
				  />
			<EditableTableRow
                label="Home Phone"
                value={editData.TreasurerHomePhone || ""}
                name="TreasurerHomePhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  <EditableTableRow
                label="Work Phone"
                value={editData.TreasurerWorkPhone || ""}
                name="TreasurerWorkPhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

			  <EditableTableRow
                label="Other Phone"
                value={editData.TreasurerOtherPhone || ""}
                name="TreasurerOtherPhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="Local Address"
                value={editData.TreasurerLocalAddress || ""}
                name="TreasurerLocalAddress"
                onChange={handleInputChange}
                isEditMode={isEditMode}
				/>
				</table>
				</div>
				<div className="grid-item">
            <h4>Local Secretary</h4>
            <table>
              <EditableTableRow
                label="First Name"
                value={editData.RecordingSecretaryFirstName || ""}
                name="RecordingSecretaryFirstName"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

              <EditableTableRow
                label="Last Name"
                value={editData.RecordingSecretaryLastName || ""}
                name="RecordingSecretaryLastName"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
              <EditableTableRow
                label="Street"
                value={editData.RecordingSecretaryAddress || ""}
                name="RecordingSecretaryAddress"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

			<EditableTableRow
                label="City"
                value={editData.RecordingSecretaryCity || ""}
                name="RecordingSecretaryCity"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="State"
                value={editData.RecordingSecretaryState || ""}
                name="RecordingSecretaryState"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="Zip Code"
                value={editData.RecordingSecretaryZipcode || ""}
                name="RecordingSecretaryZipcode"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
				<EditableTableRow
					label="Cell Phone"
					value={editData.RecordingSecretaryCellPhone || ""}
					name="RecordingSecretaryCellPhone"
					onChange={handleInputChange}
					isEditMode={isEditMode}
				  />
			<EditableTableRow
                label="Home Phone"
                value={editData.RecordingSecretaryHomePhone || ""}
                name="RecordingSecretaryHomePhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  <EditableTableRow
                label="Work Phone"
                value={editData.RecordingSecretaryWorkPhone || ""}
                name="RecordingSecretaryWorkPhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />

			  <EditableTableRow
                label="Other Phone"
                value={editData.RecordingSecretaryOtherPhone || ""}
                name="RecordingSecretaryOtherPhone"
                onChange={handleInputChange}
                isEditMode={isEditMode}
              />
			  
			  <EditableTableRow
                label="Local Address"
                value={editData.RecordingSecretaryLocalAddress || ""}
                name="RecordingSecretaryLocalAddress"
                onChange={handleInputChange}
                isEditMode={isEditMode}
				/>
				</table>
			{/*
			<EditableTableRow
			label="Vice President First Name"
			value={editData.VicePresidentFirstName || ""}
			  name="VicePresidentFirstName"
			  onChange={handleInputChange}
			  isEditMode={isEditMode}
              />
			  
              <EditableTableRow
			  label="Vice President Last Name"
			  value={editData.VicePresidentLastName || ""}
			  name="VicePresidentLastName"
			  onChange={handleInputChange}
			  isEditMode={isEditMode}
              />
              <EditableTableRow
			  label="Treasurer First Name"
			  value={editData.TreasurerFirstName || ""}
			  name="TreasurerFirstName"
			  onChange={handleInputChange}
			  isEditMode={isEditMode}
              />
			  
              <EditableTableRow
			  label="Treasurer Last Name"
			  value={editData.TreasurerLastName || ""}
			  name="TreasurerLastName"
			  onChange={handleInputChange}
			  isEditMode={isEditMode}
              />
			  
              <EditableTableRow
			  label="Secretary First Name"
			  value={editData.RecordingSecretaryFirstName || ""}
			  name="RecordingSecretaryFirstName"
			  onChange={handleInputChange}
			  isEditMode={isEditMode}
              />
			  
              <EditableTableRow
			  label="Secretary Last Name"
			  value={editData.RecordingSecretaryLastName || ""}
			  name="RecordingSecretaryLastName"
			  onChange={handleInputChange}
			  isEditMode={isEditMode}
              />
			*/}
			</div>

          {/* Additional Notes */}
          <div
            className="grid-item"
            style={{ textAlign: "left", overflow: "auto", maxHeight: "350px" }}
          >
            <h4>Notes</h4>
            <table
              style={{ textAlign: "left", overflow: "auto", height: "80%" }}
            >
              {notes.map((note, index) => (
                <tr key={note.id}>
                  <td style={{ textAlign: "left", width: "30%" }}>
                    {formatDate(note.datetime)}
                  </td>
                  <td>
                    {isEditMode ? (
                      <input
                        style={{ width: "90%" }}
                        type="text"
                        value={note.note}
                        onChange={(e) =>
                          handleNoteChange(index, e.target.value)
                        }
                      />
                    ) : (
                      note.note + " [ " + note.employeeName + " ]"
                    )}
                  </td>
                  {isEditMode && (
                    <td style={{ width: "10%" }}>
                      <button onClick={() => deleteNote(note.id, index)}>
                        Delete
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </table>
            {isEditMode && (
              <div>
                <input
                  style={{ width: "75%", padding: "5px", marginTop: "10px" }}
                  type="text"
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                  placeholder="Add a new note"
                />
                <button
                  style={{
                    float: "right",
                    padding: "3px",
                    marginTop: "10px",
                    borderRadius: "2px",
                    backgroundColor: "lightgreen",
                  }}
                  onClick={addNote}
                >
                  Add Note
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default BargainingUnitInfo;
