import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Nav from './../navigation/Nav';
import './../styles/towns/Towns.css';
import MapComponent from './../maps/MapComponent.js';

function Towns() {
    const [groupedTowns, setGroupedTowns] = useState({});
    const location = useLocation();
    const navigate = useNavigate();
    const letterRefs = useRef({});

    useEffect(() => {
        const token = sessionStorage.getItem("token");
        if (!token) {
            navigate("/login");
            return;
        }

        fetch(`/api/towns`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        })
            .then(response => {
                if (response.status === 401) {
                    sessionStorage.removeItem("token");
                    navigate("/login");
                    throw new Error("Unauthorized");
                }
                return response.json();
            })
            .then(data => {
                const sortedTowns = data.sort((a, b) => a.Name.localeCompare(b.Name));
                const grouped = sortedTowns.reduce((acc, town) => {
                    const firstLetter = town.Name.charAt(0).toUpperCase();
                    if (!acc[firstLetter]) {
                        acc[firstLetter] = [];
                    }
                    acc[firstLetter].push(town);
                    return acc;
                }, {});
                setGroupedTowns(grouped);
            })
            .catch(error => console.error('Error fetching town info:', error));
    }, [navigate]);

    // Scroll to letter section if state indicates we should
    useEffect(() => {
        if (location.state && location.state.scrollToLetter) {
            const letterToScrollTo = location.state.scrollToLetter;
            
            // Need a small timeout to ensure the refs are populated
            setTimeout(() => {
                if (letterRefs.current[letterToScrollTo]) {
                    letterRefs.current[letterToScrollTo].scrollIntoView({ 
                        behavior: 'smooth',
                        block: 'start'
                    });
                }
            }, 300);
        }
    }, [location.state, groupedTowns]);

    const letters = Object.keys(groupedTowns);

    return (
        <div className="page-container">
            <Nav />
            
            <div className="content-wrapper">
                <div className="map-container">
                    <MapComponent /> 
                </div>

                <header className="page-header">
                    <h1 className="page-title">All Towns</h1>
                </header>

                {/* Alphabet navigation bar */}
                <div className="alphabet-nav">
                    {letters.map(letter => (
                        <a
                            key={letter}
                            href={`#${letter}`}
                            className="alphabet-link"
                        >
                            {letter}
                        </a>
                    ))}
                </div>

                <main className="main-content">
                    <div className="towns-list-container">
                        {Object.entries(groupedTowns).map(([letter, towns]) => (
                            <div 
                                key={letter} 
                                id={letter} 
                                ref={el => letterRefs.current[letter] = el}
                                className="letter-section"
                            >
                                <h2 className="letter-heading">{letter}</h2>
                                <div className="towns-letter-group">
                                    {towns.map((townItem, index) => (
                                        <div
                                            className="town-item"
                                            key={townItem.id || index}
                                        >
                                            <Link 
                                                to={`/town/${townItem.Name}`}
                                                state={{ fromLetter: letter }}
                                                className="town-link"
                                            >
                                                <span className="town-name">{townItem.Name}</span> 
                                                <span className="town-count">{townItem.TownCount}</span>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </main>
            </div>
        </div>
    );
}

export default Towns;