import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { AuthProvider } from './components/general/AuthContext';
import Staff from './components/staff/Staff';
import EmployeeDetails from './components/staff/EmployeeDetails';
import BargainingUnits from './components/units/BargainingUnits';
import BargainingUnitInfo from './components/units/BargainingUnitInfo';
import Towns from './components/towns/Towns';
import Town from './components/towns/Town';
import UnassignedUnits from './components/units/ManageLocals';
import ScrollToTop from './components/general/ScrollToTop';
import Metrics from './components/metrics/Metrics';
import AuditLog from './components/audit/AuditLog';
import Login from './components/general/Login';
import { initGA, logPageView } from './analytics';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    initGA();
    logPageView();
  }, []);

  useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <AuthProvider>
      <ScrollToTop />
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />

        {/* Protected Routes */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Metrics />
            </ProtectedRoute>
          }
        />
        <Route
          path="/staff"
          element={
            <ProtectedRoute>
              <Staff />
            </ProtectedRoute>
          }
        />
        <Route
  path="/units/:id"
  element={
    <ProtectedRoute>
      <BargainingUnitInfo />
    </ProtectedRoute>
  }
/>
        <Route
          path="/employee/:id"
          element={
            <ProtectedRoute>
              <EmployeeDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/units"
          element={
            <ProtectedRoute>
              <BargainingUnits />
            </ProtectedRoute>
          }
        />
        <Route
          path="/unit-info/:id"
          element={

              <BargainingUnitInfo />

          }
        />
        <Route
          path="/towns"
          element={
            <ProtectedRoute>
              <Towns />
            </ProtectedRoute>
          }
        />
        <Route
          path="/town/:town"
          element={
            <ProtectedRoute>
              <Town />
            </ProtectedRoute>
          }
        />
        <Route
          path="/unassigned-units"
          element={
            <ProtectedRoute>
              <UnassignedUnits />
            </ProtectedRoute>
          }
        />
        <Route
          path="/metrics"
          element={
            <ProtectedRoute>
              <Metrics />
            </ProtectedRoute>
          }
        />
        <Route
          path="/audit-log"
          element={
            <ProtectedRoute>
              <AuditLog />
            </ProtectedRoute>
          }
        />
      </Routes>
    </AuthProvider>
  );
};

export default App;