import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Nav from './../navigation/Nav';
import { useAuth } from './../general/AuthContext';
import './../styles/staff/Staff.css';

function EmployeeDetails() {
    const { id } = useParams();
    const [employeeDetails, setEmployeeDetails] = useState(null);
    const { auth } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!auth.isAuthenticated) {
            navigate('/login');
            return;
        }
        fetchEmployeeDetails();
    }, [auth, navigate, id]);

    const fetchEmployeeDetails = async () => {
        try {
            const response = await fetch(`/api/employee/${id}`, {
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setEmployeeDetails(data);
        } catch (error) {
            console.error('Error fetching employee details:', error);
            navigate('/login');
        }
    };

    // No employee data found state
    if (!employeeDetails) {
        return (
            <div className="page-container">
                <Nav />
                <div className="content-wrapper">
                    <div className="error-message">
                        <div className="error-icon">❗</div>
                        <h2>No information found</h2>
                        <p>No info for the employee with ID: {id}</p>
                        <p>Please report to Webmaster at <a href="mailto:jonny@two-bit-operation.com">jonny@two-bit-operation.com</a>.</p>
                    </div>
                </div>
            </div>
        );
    }

    // Employee has no units state
    if (employeeDetails.length === 0) {
        return (
            <div className="page-container">
                <Nav />
                <div className="content-wrapper">
                    <div className="error-message">
                        <div className="warning-icon">⚠️</div>
                        <h2>No Units Assigned</h2>
                        <p>This employee does not have any units assigned to them.</p>
                        <Link to="/staff" className="action-button">Return to Staff Page</Link>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="page-container">
            <Nav />
            <div className="content-wrapper">
                <header className="employee-header">
                    <h1 className="employee-name">
                        {employeeDetails[0] && employeeDetails[0].employee_full_name}
                    </h1>
                    <div className="employee-role">Staff Representative</div>
                </header>

                <main className="main-content">
                    <div className="section-header">
                        <h2>Assigned Bargaining Units</h2>
                    </div>
                    
                    <div className="table-container">
                        <table className="data-table">
                            <thead>
                                <tr>
                                    <th className="sortable-header">Local</th>
                                    <th className="sortable-header">Chapter</th>
                                    <th className="sortable-header">Bargaining Unit</th>
                                    <th className="sortable-header">Town</th>
                                </tr>
                            </thead>
                            <tbody>
                                {employeeDetails.map((row, index) => (
                                    <tr key={index} className="data-row">
                                        <td className="cell">{row.UnitNumber}</td>
                                        <td className="cell">
                                            {row.UnitChapter !== null && row.UnitChapter !== undefined
                                                ? row.UnitChapter.toString().padStart(4, '0')
                                                : '—'}
                                        </td>
                                        <td className="cell">
                                            <Link to={`/unit-info/${row.ID}`} className="table-link">{row.UnitName}</Link>
                                        </td>
                                        <td className="cell">
                                            <Link to={`/town/${row.Town}`} className="table-link">{row.Town}</Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default EmployeeDetails;