import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import Nav from "./../navigation/Nav";
import MapComponent from "./../maps/MapComponent";
import "./../styles/towns/Town.css";

function Town() {
  const [townList, setTownList] = useState([]);
  const [townCoordinates, setTownCoordinates] = useState(null);
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [townFound, setTownFound] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { town } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Default coordinates for Connecticut
  const CT_DEFAULT_COORDINATES = [41.6032, -73.0877];

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    setIsMapLoading(true);
    setDataLoaded(false);
    setTownFound(true); // Reset at the beginning of each fetch

    // First, fetch the town data from the API
    fetch(`/api/town/${town}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setTownList(data);
        
        // If we got data back with items, the town exists in our database
        if (data && data.length > 0) {
          console.log(`Found ${data.length} units for ${town}`);
          setTownFound(true);
        } else {
          console.log(`No units found for ${town}`);
          setTownFound(false);
        }
        setDataLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching town info:", error);
        setTownFound(false);
        setDataLoaded(true);
      });

    // In parallel, fetch the GeoJSON for map coordinates
    fetch('/CT_Municipalities.geojson')
      .then((response) => response.json())
      .then((data) => {
        try {
          const townFeature = data.features.find(
            (feature) => feature.properties.Municipality.toLowerCase() === town.toLowerCase()
          );

          if (townFeature && townFeature.geometry) {
            // Handle any geometry type
            const coordinates = townFeature.geometry.coordinates;
            
            // Safely extract all individual points from potentially nested arrays
            const allPoints = extractAllPoints(coordinates);
            
            if (allPoints.length > 0) {
              // Calculate average of all points
              const center = calculateAveragePoint(allPoints);
              
              if (isValidCoordinate(center)) {
                setTownCoordinates(center);
                // Don't set townFound here - it's based on API results
              } else {
                setTownCoordinates(CT_DEFAULT_COORDINATES);
                // Don't set townFound here - it's based on API results
              }
            } else {
              setTownCoordinates(CT_DEFAULT_COORDINATES);
              // Don't set townFound here - it's based on API results
            }
          } else {
            setTownCoordinates(CT_DEFAULT_COORDINATES);
            // Don't set townFound here - it's based on API results
          }
        } catch (error) {
          console.error("Error processing GeoJSON data:", error);
          setTownCoordinates(CT_DEFAULT_COORDINATES);
          // Don't set townFound here - it's based on API results
        } finally {
          setIsMapLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching GeoJSON:", error);
        setTownCoordinates(CT_DEFAULT_COORDINATES);
        setIsMapLoading(false);
        // Don't set townFound here - it's based on API results
      });
  }, [town, navigate]);

  // Function to handle back button click
  const handleBackClick = () => {
    // Check if we came from the Towns page with a specific letter hash
    if (location.state && location.state.fromLetter) {
      navigate('/towns', { state: { scrollToLetter: location.state.fromLetter } });
    } else {
      // Get the first letter of the current town to jump to that section
      const firstLetter = town.charAt(0).toUpperCase();
      navigate('/towns', { state: { scrollToLetter: firstLetter } });
    }
  };

  // Function to extract all individual points from potentially nested coordinate arrays
  const extractAllPoints = (coordinates) => {
    const points = [];
    
    // Recursively extract points from nested arrays
    const extract = (coord) => {
      if (Array.isArray(coord)) {
        if (coord.length === 2 && typeof coord[0] === 'number' && typeof coord[1] === 'number') {
          // This is a point [longitude, latitude]
          points.push(coord);
        } else {
          // This is a nested array, process each element
          coord.forEach(c => extract(c));
        }
      }
    };
    
    extract(coordinates);
    return points;
  };

  // Function to calculate the average of all points
  const calculateAveragePoint = (points) => {
    if (points.length === 0) return null;
    
    let sumLat = 0;
    let sumLon = 0;
    let validPoints = 0;
    
    points.forEach(point => {
      const lon = point[0];
      const lat = point[1];
      
      if (!isNaN(lon) && !isNaN(lat)) {
        sumLon += lon;
        sumLat += lat;
        validPoints++;
      }
    });
    
    if (validPoints === 0) return null;
    
    // Return as [latitude, longitude] for Leaflet
    return [sumLat / validPoints, sumLon / validPoints];
  };

  // Function to validate a coordinate
  const isValidCoordinate = (coord) => {
    if (!coord || !Array.isArray(coord) || coord.length !== 2) return false;
    return !isNaN(coord[0]) && !isNaN(coord[1]) && 
           Math.abs(coord[0]) <= 90 && Math.abs(coord[1]) <= 180;
  };

  return (
    <div className="page-container">
      <Nav />
      
      <div className="content-wrapper town-page">
        <div className="map-container">
          {isMapLoading ? (
            <div className="map-loading">
              <div className="loading-spinner"></div>
              <p>Loading map...</p>
            </div>
          ) : (
            <MapComponent 
              center={townCoordinates} 
              zoom={townCoordinates[0] === CT_DEFAULT_COORDINATES[0] ? 8 : 12} 
            />
          )}
        </div>

        <div className="page-actions">
          <button 
            onClick={handleBackClick}
            className="back-button"
          >
            ← Back to Towns
          </button>
        </div>

        <header className="page-header">
          <h1 className="page-title">All Units in {town}</h1>
        </header>
        
        <main className="main-content">
          {!dataLoaded ? (
            <div className="loading-state">
              <div className="loading-spinner"></div>
              <p>Loading data...</p>
            </div>
          ) : !townFound || townList.length === 0 ? (
            <div className="empty-state">
              <div className="warning-icon">⚠️</div>
              <h2>No Units Found</h2>
              <p>There are currently no bargaining units in {town}.</p>
            </div>
          ) : (
            <div className="units-list">
              {townList.map((townItem, index) => (
                <div className="unit-item" key={index}>
                  <Link to={`/unit-info/${townItem.id}`} className="unit-link">
                    <span className="unit-code">
                      {townItem.LOCAL} - {townItem.UNIT.toString().padStart(4, "0")}
                    </span>&nbsp; - &nbsp;
                    <span className="unit-name">{townItem.NAME}</span>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default Town;