import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './../styles/staff/Staff.css';
import './../styles/general/Reset.css';
import Nav from './../navigation/Nav';
import { useAuth } from './../general/AuthContext';

function UnassignedUnits() {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [unassignedUnitList, setUnassignedUnitList] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [staffReps, setStaffReps] = useState([]);
  const [organizers, setOrganizers] = useState([]);
  const [serviceReps, setServiceReps] = useState([]);
  const [fieldReps, setFieldReps] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState({});
  const [sortConfig, setSortConfig] = useState({
    key: 'LOCAL',
    direction: 'ascending',
  });
  const [isLoading, setIsLoading] = useState(true);

  // Handle employee selection change
  const handleEmployeeChange = (unitId, employeeNumber, event) => {
    setSelectedEmployees(prevState => ({
      ...prevState,
      [unitId]: {
        ...prevState[unitId],
        [`employee${employeeNumber}`]: event.target.value,
      },
    }));
  };

  // Handle form submission
  const handleSubmit = unitId => {
    const selectedEmployeesForUnit = selectedEmployees[unitId] || {};
    
    const employee1Id = String(selectedEmployeesForUnit.employee1 || '');
    const employee2Id = String(selectedEmployeesForUnit.employee2 || '');
    const employee3Id = String(selectedEmployeesForUnit.employee3 || '');
    const employee4Id = String(selectedEmployeesForUnit.employee4 || '');
    const employee5Id = String(selectedEmployeesForUnit.employee5 || '');
    
    const employee1Name = employees.find(emp => String(emp.EmployeeId) === employee1Id)?.full_name || '';
    const employee2Name = employees.find(emp => String(emp.EmployeeId) === employee2Id)?.full_name || '';
    const employee3Name = serviceReps.find(emp => String(emp.EmployeeId) === employee3Id)?.full_name || '';
    const employee4Name = fieldReps.find(emp => String(emp.EmployeeId) === employee4Id)?.full_name || '';
    const employee5Name = organizers.find(emp => String(emp.EmployeeId) === employee5Id)?.full_name || '';
    
    const submission = {
      ...selectedEmployeesForUnit,
      employee1Name,
      employee2Name,
      employee3Name,
      employee4Name,
      employee5Name,
    };

    fetch(`/api/assign-employees/${unitId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`,
      },
      body: JSON.stringify(submission),
    })
      .then(response => {
        if (response.ok) {
          // Remove the assigned unit from the list
          setUnassignedUnitList(prevList => 
            prevList.filter(unit => unit.id !== unitId)
          );
          
          // Show success message
          alert('Employees assigned successfully');
        } else {
          alert('Error assigning employees: ' + response.statusText);
        }
      })
      .catch(error => {
        console.error('Error assigning employees: ', error);
        alert('Error assigning employees');
      });
  };

  // Fetch all required data when component mounts
  useEffect(() => {
    if (!auth.isAuthenticated) {
      navigate('/login');
      return;
    }

    setIsLoading(true);

    // Fetch all data in parallel
    Promise.all([
      fetch('/api/staff-reps', {
        headers: { Authorization: `Bearer ${auth.token}` },
      }).then(res => res.json()),
      
      fetch('/api/service-reps', {
        headers: { Authorization: `Bearer ${auth.token}` },
      }).then(res => res.json()),
      
      fetch('/api/organizers', {
        headers: { Authorization: `Bearer ${auth.token}` },
      }).then(res => res.json()),
      
      fetch('/api/field-reps', {
        headers: { Authorization: `Bearer ${auth.token}` },
      }).then(res => res.json()),
      
      fetch('/api/unassigned-units', {
        headers: { Authorization: `Bearer ${auth.token}` },
      }).then(res => res.json()),
    ])
      .then(([staffData, serviceData, organizerData, fieldData, unitsData]) => {
        setEmployees(staffData);
        setStaffReps(staffData);
        setServiceReps(serviceData);
        setOrganizers(organizerData);
        setFieldReps(fieldData);
        setUnassignedUnitList(unitsData);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setAuth({ isAuthenticated: false, token: null });
        navigate('/login');
      });
  }, [auth, navigate, setAuth]);

  // Sort data when column header is clicked
  const sortData = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    setUnassignedUnitList(unassignedUnitList =>
      [...unassignedUnitList].sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      })
    );
  };

  return (
    <div className="page-container">
      <Nav />
      <div className="content-wrapper">
        <header className="page-header">
          <h1 className="page-title">Unassigned Bargaining Units</h1>
        </header>
        
        <main className="main-content">
          {isLoading ? (
            <div className="loading-state">
              <div className="loading-spinner"></div>
              <p>Loading data...</p>
            </div>
          ) : unassignedUnitList.length === 0 ? (
            <div className="empty-state">
              <div className="success-icon">✓</div>
              <h2>All Units Assigned</h2>
              <p>There are currently no unassigned bargaining units.</p>
            </div>
          ) : (
            <div className="table-container">
              <table className="data-table">
                <thead>
                  <tr>
                    {['LOCAL', 'UNIT', 'NAME', 'TOWN'].map(column => (
                      <th
                        key={column}
                        onClick={() => sortData(column)}
                        className={`sortable-header ${sortConfig.key === column ? `sorted-${sortConfig.direction}` : ""}`}
                      >
                        {column}
                        {sortConfig.key === column && (
                          <span className="sort-indicator">
                            {sortConfig.direction === "ascending" ? " ▲" : " ▼"}
                          </span>
                        )}
                      </th>
                    ))}
                    <th>Staff Rep</th>
                    <th>Service Rep</th>
                    <th>Field Rep</th>
                    <th>Organizer</th>
                    <th>Staff Rep 2</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {unassignedUnitList.map(unit => (
                    <tr key={unit.id} className="data-row">
                      <td className="cell">{unit.LOCAL}</td>
                      <td className="cell">{unit.UNIT.toString().padStart(4, '0')}</td>
                      <td className="cell">
                        <Link to={`/unit-info/${unit.id}`} className="table-link">{unit.NAME}</Link>
                      </td>
                      <td className="cell">{unit.TOWN}</td>
                      <td className="cell">
                        <select 
                          className="form-select"
                          value={selectedEmployees[unit.id]?.employee1 || ''}
                          onChange={event => handleEmployeeChange(unit.id, 1, event)}
                        >
                          <option value="">--</option>
                          {employees.map(employee => (
                            <option key={employee.EmployeeId} value={employee.EmployeeId}>
                              {employee.full_name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="cell">
                        <select 
                          className="form-select"
                          value={selectedEmployees[unit.id]?.employee3 || ''}
                          onChange={event => handleEmployeeChange(unit.id, 3, event)}
                        >
                          <option value="">--</option>
                          {serviceReps.map(serviceRep => (
                            <option key={serviceRep.EmployeeId} value={serviceRep.EmployeeId}>
                              {serviceRep.full_name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="cell">
                        <select 
                          className="form-select"
                          value={selectedEmployees[unit.id]?.employee4 || ''}
                          onChange={event => handleEmployeeChange(unit.id, 4, event)}
                        >
                          <option value="">--</option>
                          {fieldReps.map(fieldRep => (
                            <option key={fieldRep.EmployeeId} value={fieldRep.EmployeeId}>
                              {fieldRep.full_name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="cell">
                        <select 
                          className="form-select"
                          value={selectedEmployees[unit.id]?.employee5 || ''}
                          onChange={event => handleEmployeeChange(unit.id, 5, event)}
                        >
                          <option value="">--</option>
                          {organizers.map(organizer => (
                            <option key={organizer.EmployeeId} value={organizer.EmployeeId}>
                              {organizer.full_name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="cell">
                        <select 
                          className="form-select"
                          value={selectedEmployees[unit.id]?.employee2 || ''}
                          onChange={event => handleEmployeeChange(unit.id, 2, event)}
                        >
                          <option value="">--</option>
                          {employees.map(employee => (
                            <option key={employee.EmployeeId} value={employee.EmployeeId}>
                              {employee.full_name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="cell">
                        <button 
                          className="action-button"
                          onClick={() => handleSubmit(unit.id)}
                        >
                          Assign
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default UnassignedUnits;