import React, { useState, useEffect, useRef } from "react";

function DraggableEditButton({ isEditMode, handleEditToggle, handleSave }) {
  // State for button position - default to center of the light blue area in screenshot
  const [position, setPosition] = useState({ x: 500, y: 50 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [isDropping, setIsDropping] = useState(false);
  const buttonRef = useRef(null);

  // Load saved position from localStorage on component mount
  useEffect(() => {
    const savedPosition = localStorage.getItem('editButtonPosition');
    if (savedPosition) {
      try {
        setPosition(JSON.parse(savedPosition));
      } catch (e) {
        console.error('Failed to parse saved position', e);
      }
    }
  }, []);

  // Save position to localStorage when it changes
  useEffect(() => {
    if (!isDragging) {
      localStorage.setItem('editButtonPosition', JSON.stringify(position));
    }
  }, [position, isDragging]);

  // Handler for mouse down event to start dragging
  const handleMouseDown = (e) => {
    // Don't drag when clicking on the actual buttons
    if (e.target.tagName === 'SPAN') return;
    
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setDragOffset({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      });
      setIsDragging(true);
    }
  };

  // Handler for mouse move event during dragging
  const handleMouseMove = (e) => {
    if (isDragging) {
      // Calculate boundaries of the viewport
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      
      // Get button dimensions
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const buttonWidth = buttonRect.width;
      const buttonHeight = buttonRect.height;
      
      // Calculate new position
      let newX = e.clientX - dragOffset.x;
      let newY = e.clientY - dragOffset.y;
      
      // Constrain to viewport bounds with padding
      const padding = 10;
      newX = Math.max(padding, Math.min(newX, viewportWidth - buttonWidth - padding));
      newY = Math.max(padding, Math.min(newY, viewportHeight - buttonHeight - padding));
      
      setPosition({ x: newX, y: newY });
    }
  };

  // Handler for mouse up event to stop dragging
  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);
      setIsDropping(true);
      
      // Reset the dropping animation after it completes
      setTimeout(() => {
        setIsDropping(false);
      }, 600); // Longer animation duration
    }
  };

  // Add and remove event listeners for dragging
  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  // Create animation style
  useEffect(() => {
    // Add this to your CSS
    const dropAnimationStyle = `
    @keyframes dropAnimation {
      0% { transform: translateY(-10px); opacity: 0.7; }
      40% { transform: translateY(15px); }
      70% { transform: translateY(-7px); }
      85% { transform: translateY(4px); }
      100% { transform: translateY(0); opacity: 1; }
    }
    
    @keyframes shadowPulse {
      0% { box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
      20% { box-shadow: 0 0 25px rgba(0, 0, 0, 0.3); }
      100% { box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
    }
    `;

    // Add the style to the document
    const styleElement = document.createElement('style');
    styleElement.innerHTML = dropAnimationStyle;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return (
    <div
      ref={buttonRef}
      style={{
        position: 'fixed',
        top: `${position.y}px`,
        left: `${position.x}px`,
        cursor: isDragging ? 'grabbing' : 'grab',
        userSelect: 'none',
        zIndex: 1000,
        padding: '25px', // Wider draggable border
        backgroundColor: '#ecf8f9dd', // Slightly more opaque
        borderRadius: '12px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        border: '2px dashed #aadcdf', // Visible border to indicate draggability
        animation: isDropping 
          ? 'dropAnimation 0.6s ease-in-out, shadowPulse 0.6s ease-in-out' 
          : 'none',
        transition: 'box-shadow 0.3s ease'
      }}
      onMouseDown={handleMouseDown}
    >
      <span
        style={{
          fontSize: "20px",
          cursor: "pointer",
          padding: "11px",
          textAlign: "left",
          fontWeight: "bold",
          color: "#fff",
          backgroundColor: "red",
          borderRadius: "8px",
          display: "inline-block",
        }}
        onClick={handleEditToggle}
      >
        {isEditMode ? "Cancel" : "Edit Unit Information"}
      </span>

      {isEditMode && (
        <span
          onClick={handleSave}
          style={{
            fontSize: "20px",
            cursor: "pointer",
            padding: "10px",
            textAlign: "center",
            backgroundColor: "lightgreen",
            width: "100px",
            borderRadius: "8px",
            marginLeft: "10px",
            fontWeight: 700,
            color: "#000",
            display: "inline-block",
          }}
        >
          Save
        </span>
      )}
      
      {/* Optional: Add a visual indicator that this element is draggable */}
      <div style={{
        fontSize: '12px', 
        textAlign: 'center', 
        marginTop: '8px',
        color: '#666',
        cursor: 'grab'
      }}>
        ⋯⋯ Drag me ⋯⋯
      </div>
    </div>
  );
}

export default DraggableEditButton;