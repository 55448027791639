import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { jwtDecode } from 'jwt-decode';
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({ token: null, isAuthenticated: false, user: null });
    const navigate = useNavigate();

    useEffect(() => {
        const initializeAuth = () => {
            const token = sessionStorage.getItem('token');
            if (token) {
                try {
                    const decodedUser = jwtDecode(token); // Decode token to get user details
                    setAuth({ token, isAuthenticated: true, user: decodedUser });
                } catch (error) {
                    console.error("Invalid token:", error);
                    handleLogout(); // Clear auth and redirect
                }
            } else {
                navigate('/login', { replace: true }); // Replace ensures no unnecessary history
            }
        };

        const handleLogout = () => {
            setAuth({ token: null, isAuthenticated: false, user: null });
            sessionStorage.removeItem('token');
            navigate('/login', { replace: true });
        };

        initializeAuth();
    }, [navigate]);

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);