import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "./../navigation/Nav";
import "./../styles/metrics/Metrics.css";

function MetricsPage() {
  const navigate = useNavigate();
  const [metrics, setMetrics] = useState({
    bargainingUnitsCount: 0,
    totalActiveJobs: 0,
    totalOptOuts: 0,
    peopleContributors: 0,
    totalDuesPayers: 0,
    totalRecommitsSaves: 0,
    UnassignedUnits: 0
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    setIsLoading(true);

    fetch("/api/metrics", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          sessionStorage.removeItem("token");
          navigate("/login");
          throw new Error("Unauthorized");
        }
        return response.json();
      })
      .then((data) => {
        // Ensure all metric values are at least 0 to prevent null values
        const sanitizedData = {
          bargainingUnitsCount: data.bargainingUnitsCount || 0,
          totalActiveJobs: data.totalActiveJobs || 0,
          totalOptOuts: data.totalOptOuts || 0,
          peopleContributors: data.peopleContributors || 0,
          totalDuesPayers: data.totalDuesPayers || 0,
          totalRecommitsSaves: data.totalRecommitsSaves || 0,
          UnassignedUnits: data.UnassignedUnits || 0
        };
        setMetrics(sanitizedData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching metrics:", error);
        setIsLoading(false);
      });
  }, [navigate]);

  // Metric card data with icons and descriptions
  const metricCards = [
    {
      title: "Bargaining Units",
      value: metrics.bargainingUnitsCount,
      icon: "📋",
      description: "Total number of bargaining units",
      color: "#4361ee"
    },
    {
      title: "Active Jobs",
      value: metrics.totalActiveJobs,
      icon: "💼",
      description: "Current active jobs in the system",
      color: "#3a86ff"
    },
    {
      title: "Opt Outs",
      value: metrics.totalOptOuts,
      icon: "🚪",
      description: "Members who have opted out",
      color: "#ff006e"
    },
    {
      title: "People Contributors",
      value: metrics.peopleContributors,
      icon: "👥",
      description: "Total PEOPLE contributors",
      color: "#8338ec"
    },
    {
      title: "Dues Payers",
      value: metrics.totalDuesPayers,
      icon: "💵",
      description: "Total active dues payers",
      color: "#fb5607"
    },
    {
      title: "Recommits/Saves",
      value: metrics.totalRecommitsSaves,
      icon: "🔄",
      description: "Total recommitments and saves",
      color: "#06d6a0"
    },
    {
      title: "Unassigned Units",
      value: metrics.UnassignedUnits,
      icon: "📭",
      description: "Units requiring assignment",
      color: "#ffbe0b"
    }
  ];

  return (
    <div className="page-container">
      <Nav />
      <div className="content-wrapper">
        <header className="page-header">
          <h1 className="page-title">Metrics Dashboard</h1>
        </header>

        <main className="main-content">
          {isLoading ? (
            <div className="loading-state">
              <div className="loading-spinner"></div>
              <p>Loading metrics...</p>
            </div>
          ) : (
            <div className="metrics-grid">
              {metricCards.map((card, index) => (
                <div 
                  className="metric-card" 
                  key={index}
                  style={{ borderColor: card.color }}
                >
                  <div className="metric-icon" style={{ backgroundColor: card.color }}>
                    <span>{card.icon}</span>
                  </div>
                  <div className="metric-content">
                    <h2 className="metric-title">{card.title}</h2>
                    <div className="metric-value">
                      {/* Add null check before calling toLocaleString */}
                      {(card.value !== null && card.value !== undefined) 
                        ? card.value.toLocaleString() 
                        : '0'}
                    </div>
                    <p className="metric-description">{card.description}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default MetricsPage;