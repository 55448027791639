import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './../styles/staff/Staff.css';
import './../styles/general/Reset.css';
import NavBar from './../navigation/Nav';
import { useAuth } from './../general/AuthContext';

function Staff() {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [staffReps, setStaffReps] = useState([]);
  const [serviceReps, setServiceReps] = useState([]);
  const [fieldReps, setFieldReps] = useState([]);
  const [organizers, setOrganizers] = useState([]);

  useEffect(() => {
    // Redirect to login if not authenticated
    if (!auth.isAuthenticated) {
      navigate('/login');
      return;
    }

    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Redirecting to login.");
      navigate('/login');
      return;
    }

    // Fetch data from protected endpoints
    const endpoints = ['/api/staff-reps', '/api/service-reps', '/api/field-reps', '/api/organizers'];
    const stateSetters = [setStaffReps, setServiceReps, setFieldReps, setOrganizers];

    endpoints.forEach((endpoint, index) => {
      fetch(endpoint, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
        .then(response => {
          if (response.status === 401) {
            throw new Error('Unauthorized');
          }
          return response.json();
        })
        .then(data => stateSetters[index](data))
        .catch(error => {
          console.error(`Error fetching data from ${endpoint}:`, error);
          if (error.message === 'Unauthorized') {
            setAuth({ isAuthenticated: false, token: null });
            navigate('/login');
          }
        });
    });
  }, [auth, navigate, setAuth]);

  const renderTable = (data, title) => (
    <div className="staff-table-container">
      <div className="staff-table-header">
        <h2>{title}</h2>
      </div>
      <div className="staff-list">
        {data.map((staff, index) => (
          <Link 
            key={index} 
            to={`/employee/${staff.EmployeeId}`} 
            className="staff-item"
          >
            {staff.full_name}
          </Link>
        ))}
      </div>
    </div>
  );

  return (
    <div className="page-container">
      <NavBar />
      <div className="content-wrapper">
        <header className="page-header">
          <h1 className="page-title">Employee Directory</h1>
        </header>
        <main className="main-content">
          <div className="staff-directory-container">
            {renderTable(staffReps, 'Staff Representatives')}
            {renderTable(serviceReps, 'Service Representatives')}
            {renderTable(fieldReps, 'Field Representatives')}
            {renderTable(organizers, 'Organizers')}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Staff;